import axios from "axios";

//const VITE_SERVER_HOST = "http://localhost:8080";
const VITE_SERVER_HOST = "http://online-shop.onedash.dev/api";

// Payment
export async function createPaymentIntent(setClientSecret, totalAmount) {
	await axios
		.post(`${VITE_SERVER_HOST}/payment/create-payment-intent`, { amount: Math.round(totalAmount) })
		.then((res) => {
			let { clientSecret } = res.data;
			setClientSecret(clientSecret);
		});
}
export async function getStripeKey() {
	return await axios.get(`${VITE_SERVER_HOST}/payment/config`).then((res) => {
		const { publishableKey } = res.data;
		return publishableKey;
	});
}
// Items
export async function getAllItems(setItems) {
	await axios.get(`${VITE_SERVER_HOST}/items`).then((res) => {
		setItems(res.data);
	});
}
export function changeItems(items, setItems, keycloak) {
	const config = {
		headers: {
			Authorization: `Bearer ${keycloak.token}`,
		},
	};
	axios.post(`${VITE_SERVER_HOST}/items/edit`, { items }, config).then(() => {
		setItems((prevItems) => {
			const newItems = [...prevItems];
			items.forEach((item) => {
				const index = newItems.findIndex((i) => i.id === item.id);
				newItems[index] = item;
			});
			return newItems;
		});
	});
}
export function addItems(items, setItems, keycloak) {
	const config = {
		headers: {
			Authorization: `Bearer ${keycloak.token}`,
		},
	};
	axios.post(`${VITE_SERVER_HOST}/items`, { items }, config).then((res) => {
		setItems((prevItems) => [...prevItems, ...res.data]);
	});
}
export function deleteItems(items, setItems, keycloak) {
	const config = {
		headers: {
			Authorization: `Bearer ${keycloak.token}`,
		},
	};
	axios.post(`${VITE_SERVER_HOST}/items/delete`, { items }, config).then(() => {
		setItems((prevItems) => prevItems.filter((item) => !items.includes(item.id)));
	});
}
// Orders
export function createOrders(user, cartItems) {
	axios.post(`${VITE_SERVER_HOST}/orders`, { user, cartItems });
}
export function getAllOrders(setOrders) {
	axios.get(`${VITE_SERVER_HOST}/orders`).then((res) => {
		setOrders(res.data);
	});
}

export function getOrdersByUser(setUserOrders, user) {
	axios.get(`${VITE_SERVER_HOST}/orders/${user}`).then((res) => {
		setUserOrders(res.data);
	});
}

