import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
	url: "https://authentication.online-shop.onedash.dev/auth",
	//url: "https://localhost:4000",
	realm: "myRealm",
	clientId: "myClient",
});

export default keycloak;

