/* eslint-disable react/no-unknown-property */
import React, { useRef, useState } from "react";
import { Html, useGLTF } from "@react-three/drei";
import { useOptionContext } from "../../contexts/OptionContextProvider";

export default function Bathroom(props) {
	const { nodes, materials } = useGLTF("/models/bathroom.glb");
	const { setCategoriesOpen } = useOptionContext();
	const [hovered, setHovered] = useState(null);
	const toilet = useRef();
	const carpet = useRef();
	const shower = useRef();
	const sink = useRef();
	const towel = useRef();
	const mirror = useRef();
	return (
		<group {...props} dispose={null}>
			<group
				name="Toilet"
				ref={toilet}
				onClick={() => handleClick(toilet)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(toilet)}>
				<mesh
					geometry={nodes.toilet.geometry}
					material={materials.keramic}
					position={[-3.34, 0.8, 1.67]}
					scale={[1, 0.14, 0.55]}
					castShadow></mesh>
				<mesh
					geometry={nodes.paper.geometry}
					material={materials.keramic}
					position={[-3.76, 2.25, 2.59]}
					rotation={[1.57, 0, 0]}
					scale={0.14}
				/>
				<mesh
					geometry={nodes.holder001.geometry}
					material={materials.metall}
					position={[-1.99, 9.22, 0.23]}
					scale={0.13}
				/>
				{hovered === "Toilet" && (
					<Html center className="tool-tip" position={[-3.34, 3.8, 1.67]}>
						Toilet
					</Html>
				)}
			</group>

			<mesh
				geometry={nodes.Floor.geometry}
				material={materials.floor}
				position={[3.22, 0.77, 3.22]}
				scale={0.78}
			/>
			<mesh
				geometry={nodes.carpet.geometry}
				material={materials.towel}
				position={[0.28, 0.91, 0.74]}
				rotation={[0, -0.35, 0]}
				name="Carpet"
				ref={carpet}
				onClick={() => handleClick(carpet)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(carpet)}>
				{hovered === "Carpet" && (
					<Html center className="tool-tip" position={[0, 1.5, 0]}>
						Carpet
					</Html>
				)}
			</mesh>
			<group
				name="Shower"
				ref={shower}
				onClick={() => handleClick(shower)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(shower)}>
				<mesh
					geometry={nodes.bath.geometry}
					material={materials.keramic}
					position={[-2.11, 1.3, -2.99]}
					scale={[1.92, 0.69, 1]}
				/>
				<mesh
					geometry={nodes.Glass.geometry}
					material={materials.Material}
					position={[-2.11, 1.3, -2.99]}
					scale={[1.92, 0.69, 1]}
				/>
				<mesh
					geometry={nodes.tap.geometry}
					material={materials.metall}
					position={[-2.11, 1.3, -2.99]}
					scale={[1.92, 0.69, 1]}
				/>
				<mesh
					geometry={nodes.shower.geometry}
					material={materials.metall}
					position={[-2.11, 1.3, -2.99]}
					scale={[1.92, 0.69, 1]}
				/>
				{hovered === "Shower" && (
					<Html center className="tool-tip" position={[-2.11, 4.3, -3.15]}>
						Shower
					</Html>
				)}
			</group>

			<mesh geometry={nodes.Ground.geometry} material={materials.base} />
			<mesh geometry={nodes.Walls.geometry} material={materials.base} position={[-4.38, 0, 0]} />
			<mesh geometry={nodes.Walls001.geometry} material={materials.base} position={[-4.38, 0, 0]} />
			<group
				name="Sink"
				ref={sink}
				onClick={() => handleClick(sink)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(sink)}>
				{hovered === "Sink" && (
					<Html center className="tool-tip" position={[2.02, 4.58, -3.4]}>
						Sink
					</Html>
				)}
				<mesh geometry={nodes.tap001.geometry} material={materials.metall} position={[2.02, 7.58, -3.4]} />
				<mesh geometry={nodes.sink.geometry} material={materials.keramic} position={[2.02, 7.58, -3.4]} />
				<mesh geometry={nodes.handles.geometry} material={materials.metall} position={[2.02, 7.58, -3.4]} />
			</group>

			<group
				name="Towel"
				ref={towel}
				onClick={() => handleClick(towel)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(towel)}>
				<mesh
					geometry={nodes.holder.geometry}
					material={materials.metall}
					position={[0.25, 5.9, -3.85]}
					rotation={[0.97, 0, 0]}
					scale={-0.23}
				/>
				<mesh
					geometry={nodes.towel.geometry}
					material={materials.towel}
					position={[0.48, 3.43, -3.46]}
					scale={[-0.14, -0.63, -0.63]}
				/>
				{hovered === "Towel" && (
					<Html center className="tool-tip" position={[0.25, 4.2, -3.95]}>
						Towel
					</Html>
				)}
			</group>
			<group
				name="Mirror"
				ref={mirror}
				onClick={() => handleClick(mirror)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(mirror)}>
				<mesh geometry={nodes.mirror.geometry} material={materials.mirror} position={[2.25, 5.69, -0.23]} />
				<mesh geometry={nodes.mirror2.geometry} material={materials.metall} position={[2.25, 5.69, -0.23]} />
				<mesh geometry={nodes.mirror3.geometry} material={materials.metall} position={[2.25, 5.69, -0.23]} />
				{hovered === "Mirror" && (
					<Html center className="tool-tip" position={[2.25, 6.69, -4.03]}>
						Mirror
					</Html>
				)}
			</group>
		</group>
	);
	//handle hoverEffect
	function handleMouseOver(ref) {
		setHovered(ref.current.name);
		document.querySelector("canvas").style.cursor = "pointer";
	}
	function handleMouseOut() {
		document.querySelector("canvas").style.cursor = "default";
		setHovered(null);
	}
	//handle click

	function handleClick(object) {
		setCategoriesOpen(object.current.name);
	}
}

useGLTF.preload("/models/bathroom.glb");
