/* eslint-disable react/no-unknown-property */
import React, { useRef, useState } from "react";
import { Html, useGLTF } from "@react-three/drei";
import { useOptionContext } from "../../contexts/OptionContextProvider";

export default function Kitchen(props) {
	const { setCategoriesOpen } = useOptionContext();
	const { nodes, materials } = useGLTF("./models/Kitchen.glb");
	const [hovered, setHovered] = useState(null);
	const fridge = useRef();
	const sink = useRef();
	const counter = useRef();
	const shelf = useRef();
	const stove = useRef();
	const cabinet = useRef();
	const extractorHood = useRef();
	const kitchenTable = useRef();

	return (
		<group {...props} dispose={null}>
			<mesh
				geometry={nodes.Floor.geometry}
				material={materials.White}
				position={[0, 0, 0.39]}
				scale={[3.6, 0.18, 3.6]}
			/>
			<group position={[-0.02, 0.27, 0.38]}>
				<mesh geometry={nodes.Cube001.geometry} material={materials.White} />
				<mesh geometry={nodes.Cube001_1.geometry} material={materials["Floor tile"]} />
			</group>
			<mesh
				geometry={nodes.Wall.geometry}
				material={materials.White}
				position={[0, 2.33, 0.39]}
				scale={[3.6, 2.5, 3.6]}
			/>
			<group
				position={[-1.82, 0.35, 3.43]}
				name="Fridge"
				ref={fridge}
				onClick={() => handleClick(fridge)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(fridge)}
				castShadow>
				{hovered === "Fridge" && (
					<Html center className="tool-tip" position={[1, 3.8, 2]}>
						Fridge
					</Html>
				)}

				<mesh geometry={nodes.Cube018.geometry} material={materials.Fridge} />
				<mesh geometry={nodes.Cube018_1.geometry} material={materials.White} />
				<mesh geometry={nodes.Cube018_2.geometry} material={materials.Handle} />
			</group>
			<group position={[0.5, 0.35, 3.2]}>
				<mesh
					geometry={nodes.Cube041_4.geometry}
					material={materials.Sink}
					name="Sink"
					ref={sink}
					onClick={() => handleClick(sink)}
					onPointerOut={() => handleMouseOut()}
					onPointerEnter={() => handleMouseOver(sink)}
					castShadow>
					{hovered === "Sink" && (
						<Html center className="tool-tip" position={[1, 2.8, 2.7]}>
							Sink
						</Html>
					)}
				</mesh>
			</group>

			<group
				name="Shelf"
				ref={shelf}
				onClick={() => handleClick(shelf)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(shelf)}
				castShadow>
				{hovered === "Shelf" && (
					<Html center className="tool-tip" position={[3.43, 4.87, -1.61]}>
						Shelf
					</Html>
				)}
				<mesh
					geometry={nodes.Hang_shelf.geometry}
					material={materials["Counter top"]}
					position={[3.43, 3.27, -1.61]}
				/>
				<mesh
					geometry={nodes.Hang_shelf001.geometry}
					material={materials["Counter top"]}
					position={[3.43, 3.95, -1.61]}
				/>
				<group position={[3.31, 3.53, -1.71]}>
					<mesh geometry={nodes.Cylinder014.geometry} material={materials["Blue jar"]} />
					<mesh geometry={nodes.Cylinder014_1.geometry} material={materials.White} />
					<mesh geometry={nodes.Cylinder014_2.geometry} material={materials["Red jar"]} />
					<mesh geometry={nodes.Cylinder014_3.geometry} material={materials["Green jar"]} />
				</group>
			</group>

			<group position={[2.75, 0.35, 3]}>
				<mesh geometry={nodes.Cube021.geometry} material={materials.Counter} />
				<mesh geometry={nodes.Cube021_1.geometry} material={materials["Counter top"]} />
			</group>
			<group position={[0.52, 0.35, 3.22]}>
				<mesh geometry={nodes.Cube041.geometry} material={materials.Counter} />
				<mesh geometry={nodes.Cube041_1.geometry} material={materials.White} />
				<mesh geometry={nodes.Cube041_2.geometry} material={materials.Handle} />
				<mesh geometry={nodes.Cube041_3.geometry} material={materials["Counter top"]} />
			</group>
			<group
				position={[2.72, 0.34, -1.59]}
				name="Counter"
				ref={counter}
				onClick={() => handleClick(counter)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(counter)}
				castShadow>
				{hovered === "Counter" && (
					<Html center className="tool-tip" position={[0, 3.0, 0]}>
						Counter
					</Html>
				)}
				<mesh geometry={nodes.Icosphere.geometry} material={materials.Handle} />
				<mesh geometry={nodes.Icosphere_1.geometry} material={materials.Counter} />
				<mesh geometry={nodes.Icosphere_2.geometry} material={materials.White} />
				<mesh geometry={nodes.Icosphere_3.geometry} material={materials["Counter top"]} />
			</group>
			<group
				position={[1.19, 3.51, 3.56]}
				name="Cabinet"
				ref={cabinet}
				onClick={() => handleClick(cabinet)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(cabinet)}
				castShadow>
				{hovered === "Cabinet" && (
					<Html center className="tool-tip" position={[0, 1.5, 0]}>
						Cabinet
					</Html>
				)}
				<mesh geometry={nodes.Icosphere005.geometry} material={materials.Handle} />
				<mesh geometry={nodes.Icosphere005_1.geometry} material={materials.Counter} />
				<mesh geometry={nodes.Icosphere005_2.geometry} material={materials.White} />
			</group>

			<mesh
				geometry={nodes.Cutting_board.geometry}
				material={materials.Table}
				position={[2.59, 2.5, -0.9]}
				rotation={[0, 1.3, 0]}
			/>
			<group
				position={[2.71, 0.34, 1.01]}
				name="Stove"
				ref={stove}
				onClick={() => handleClick(stove)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(stove)}
				castShadow>
				{hovered === "Stove" && (
					<Html center className="tool-tip" position={[0, 3, 0]}>
						Stove
					</Html>
				)}
				<mesh geometry={nodes.Cube053.geometry} material={materials.Fridge} />
				<mesh geometry={nodes.Cube053_1.geometry} material={materials.Sink} />
			</group>

			<group position={[2.39, 2.64, 0.51]}>
				<mesh geometry={nodes.Cylinder004.geometry} material={materials.Counter} />
				<mesh geometry={nodes.Cylinder004_1.geometry} material={materials["Counter top"]} />
			</group>
			<group position={[3.29, 4.19, -1.91]}>
				<mesh geometry={nodes.Cylinder006.geometry} material={materials["Counter top"]} />
				<mesh geometry={nodes.Cylinder006_1.geometry} material={materials["Jar lid"]} />
			</group>
			<group position={[-1.44, 0.34, -1.13]}>
				<mesh geometry={nodes.Cube057.geometry} material={materials.White} />
				<mesh geometry={nodes.Cube057_1.geometry} material={materials.Stove} />
			</group>

			<group
				name="Kitchen Table"
				ref={kitchenTable}
				onClick={() => handleClick(kitchenTable)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(kitchenTable)}
				castShadow>
				{hovered === "Kitchen Table" && (
					<Html center className="tool-tip" position={[0, 1.8, 1]}>
						Kitchen Table
					</Html>
				)}
				<group position={[-2.45, 0.34, -1.14]}>
					<mesh geometry={nodes.Cube061.geometry} material={materials["Counter top"]} />
					<mesh geometry={nodes.Cube061_1.geometry} material={materials.Table} />
				</group>
				<mesh geometry={nodes.Table.geometry} material={materials.Table} position={[-1.48, 1.64, -1.11]} />
				<mesh
					geometry={nodes.Cube062.geometry}
					material={materials["Counter top"]}
					position={[-0.45, 0.34, -1.14]}
				/>
				<mesh geometry={nodes.Cube062_1.geometry} material={materials.Table} position={[-0.45, 0.34, -1.14]} />
				<mesh geometry={nodes.Plate.geometry} material={materials.White} position={[-1.42, 1.74, -1.02]} />
				<mesh geometry={nodes.Doughnut.geometry} material={materials.Table} position={[-1.42, 1.84, -1.02]} />
				<group position={[-1.33, 1.74, -1.8]}>
					<mesh geometry={nodes.Cylinder015.geometry} material={materials.White} />
					<mesh geometry={nodes.Cylinder015_1.geometry} material={materials.Coffee} />
				</group>
			</group>
			<group
				position={[3.28, 4.83, 0.94]}
				name="Extractor Hood"
				ref={extractorHood}
				onClick={() => handleClick(extractorHood)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(extractorHood)}
				castShadow>
				{hovered === "Extractor Hood" && (
					<Html center className="tool-tip" position={[0, 0.5, 0]}>
						Extractor Hood
					</Html>
				)}

				<mesh geometry={nodes.Cube063.geometry} material={materials.White} />
				<mesh geometry={nodes.Cube063_1.geometry} material={materials.Table} />
			</group>

			<group position={[3.26, 2.48, 3.64]}>
				<mesh geometry={nodes.Cylinder016.geometry} material={materials["Red jar"]} />
				<mesh geometry={nodes.Cylinder016_1.geometry} material={materials.Leaves} />
			</group>
			<group position={[2.67, 2.49, 3.65]}>
				<mesh geometry={nodes.Cylinder017.geometry} material={materials["Blue jar"]} />
				<mesh geometry={nodes.Cylinder017_1.geometry} material={materials.Leaves} />
			</group>
			<group position={[-3.26, 0.32, 3.37]}>
				<mesh geometry={nodes.Cylinder018.geometry} material={materials.Coffee} />
				<mesh geometry={nodes.Cylinder018_1.geometry} material={materials["Jar lid"]} />
				<mesh geometry={nodes.Cylinder018_2.geometry} material={materials.Leaves} />
				<mesh geometry={nodes.Cylinder018_3.geometry} material={materials.Table} />
			</group>
			<mesh geometry={nodes.Stack_of_plate.geometry} material={materials.White} position={[1.3, 2.5, 3.11]} />

			<group position={[3.25, 2.48, -2.26]}>
				<mesh geometry={nodes.Cube002.geometry} material={materials.White} />
				<mesh geometry={nodes.Cube002_1.geometry} material={materials["Milk cartoon"]} />
			</group>
			<group position={[3.2, 2.48, -1.77]}>
				<mesh geometry={nodes.Cube004.geometry} material={materials.White} />
				<mesh geometry={nodes.Cube004_1.geometry} material={materials["Milk cartoon"]} />
			</group>
		</group>
	);
	//handle hoverEffect
	function handleMouseOver(ref) {
		setHovered(ref.current.name);
		document.querySelector("canvas").style.cursor = "pointer";
	}
	function handleMouseOut() {
		document.querySelector("canvas").style.cursor = "default";
		setHovered(null);
	}

	//handle click

	function handleClick(object) {
		setCategoriesOpen(object.current.name);
	}
}

useGLTF.preload("/models/Kitchen.glb");
