/* eslint-disable react/no-unknown-property */
import React, { useRef, useState } from "react";
import { Html, useGLTF } from "@react-three/drei";
import { useOptionContext } from "../../contexts/OptionContextProvider";

export default function Livingroom(props) {
	const { nodes, materials } = useGLTF("/models/livingroom.glb");
	const { setCategoriesOpen } = useOptionContext();
	const [hovered, setHovered] = useState(null);
	const sofa = useRef();
	const tv = useRef();
	const table = useRef();
	const cupboard = useRef();
	const shelf = useRef();

	return (
		<group {...props} dispose={null}>
			<mesh
				geometry={nodes.Sofa.geometry}
				material={materials.Sofa}
				position={[1.68, 0.83, -0.68]}
				rotation={[0, -0.95, 0]}
				name="Sofa"
				ref={sofa}
				onClick={() => handleClick(sofa)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(sofa)}
				castShadow>
				{hovered === "Sofa" && (
					<Html center className="tool-tip" position={[1, 3.8, -0.7]}>
						Sofa
					</Html>
				)}
			</mesh>
			<mesh
				geometry={nodes.TV.geometry}
				material={materials.TV}
				position={[-3.93, 2.65, 0.92]}
				scale={[0.08, 1, 2.1]}
				ref={tv}
				name="TV"
				onClick={() => handleClick(tv)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(tv)}
				castShadow>
				{hovered === "TV" && (
					<Html center className="tool-tip" position={[0, 3, 0]}>
						TV
					</Html>
				)}
			</mesh>
			<mesh
				geometry={nodes.Cupboard.geometry}
				material={materials.Wood}
				position={[-3.04, 1.87, -3.06]}
				scale={0.94}
				ref={cupboard}
				name="Cupboard"
				onClick={() => handleClick(cupboard)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(cupboard)}
				castShadow>
				{hovered === "Cupboard" && (
					<Html center className="tool-tip" position={[1, 2, 0]}>
						Cupboard
					</Html>
				)}
			</mesh>
			<mesh
				geometry={nodes.Carpet.geometry}
				material={materials.Carpet}
				position={[-0.85, 0.85, 1.11]}
				rotation={[0, -0.93, 0]}></mesh>
			<mesh
				geometry={nodes.Table.geometry}
				material={materials.Wood}
				position={[-0.53, 1.46, 0.92]}
				rotation={[0, 0.59, 0]}
				scale={[0.64, 1, 1.37]}
				ref={table}
				name="Table"
				onClick={() => handleClick(table)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(table)}
				castShadow>
				{hovered === "Table" && (
					<Html center className="tool-tip" position={[0, 1, 0]}>
						Table
					</Html>
				)}
			</mesh>

			<group
				position={[-4.38, 0, 0]}
				ref={shelf}
				name="Shelf"
				onClick={() => handleClick(shelf)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(shelf)}
				castShadow>
				{hovered === "Shelf" && (
					<Html center className="tool-tip" position={[6.2, 7.5, 0]}>
						Shelf
					</Html>
				)}

				<mesh geometry={nodes.Shelf.geometry} material={materials.Wood} />
				<mesh geometry={nodes.cube.geometry} material={materials.Sofa} />
				<mesh geometry={nodes.Book1.geometry} material={materials.TV} />
				<mesh geometry={nodes.Book2.geometry} material={materials.Sofa} />
				<mesh geometry={nodes.Book3.geometry} material={materials.Floor} />
				<mesh geometry={nodes.Book4.geometry} material={materials.Carpet} />
				<mesh geometry={nodes.Plant.geometry} material={materials.Plant} />
				<mesh geometry={nodes.Pot.geometry} material={materials.Floor} />
			</group>
			<mesh geometry={nodes.Ground.geometry} material={materials.Base} />
			<mesh
				geometry={nodes.Floor.geometry}
				material={materials.Floor}
				position={[0.15, 0.89, 0]}
				scale={[0.97, 1, 1]}
			/>
			<mesh geometry={nodes.Walls.geometry} material={materials.Base} position={[-4.38, 0, 0]} />
		</group>
	);

	//handle hoverEffect
	function handleMouseOver(ref) {
		setHovered(ref.current.name);
		document.querySelector("canvas").style.cursor = "pointer";
	}
	function handleMouseOut() {
		document.querySelector("canvas").style.cursor = "default";
		setHovered(null);
	}

	//handle click

	function handleClick(object) {
		setCategoriesOpen(object.current.name);
	}
}

useGLTF.preload("/models/livingroom.glb");
