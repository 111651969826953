export function addToCart(item, setCartItems, cartItems) {
	setCartItems([...cartItems, { ...item, quantity: 1 }]);
	localStorage.setItem("cartItems", JSON.stringify([...cartItems, { ...item, quantity: 1 }]));
}
export function removeFromCart(item, setCartItems, cartItems) {
	setCartItems(cartItems.filter((cartItem) => cartItem.id !== item.id));
	localStorage.setItem("cartItems", JSON.stringify(cartItems.filter((cartItem) => cartItem.id !== item.id)));
}
export function increaseQuantity(item, setCartItems, cartItems) {
	setCartItems(
		[...cartItems].map((cartItem) => {
			if (cartItem.id === item.id) {
				return {
					...cartItem,
					quantity: cartItem.quantity + 1,
				};
			} else return cartItem;
		})
	);
	localStorage.setItem(
		"cartItems",
		JSON.stringify(
			[...cartItems].map((cartItem) => {
				if (cartItem.id === item.id) {
					return {
						...cartItem,
						quantity: cartItem.quantity + 1,
					};
				} else return cartItem;
			})
		)
	);
}
export function decreaseQuantity(item, setCartItems, cartItems) {
	setCartItems(
		[...cartItems].map((cartItem) => {
			if (cartItem.id === item.id) {
				if (cartItem.quantity === 1) {
					removeFromCart(item);
					return cartItem;
				} else
					return {
						...cartItem,
						quantity: cartItem.quantity - 1,
					};
			} else return cartItem;
		})
	);
	localStorage.setItem(
		"cartItems",
		JSON.stringify(
			[...cartItems].map((cartItem) => {
				if (cartItem.id === item.id) {
					if (cartItem.quantity === 1) {
						removeFromCart(item);
						return cartItem;
					} else
						return {
							...cartItem,
							quantity: cartItem.quantity - 1,
						};
				} else return cartItem;
			})
		)
	);
}
