import React, { useEffect } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { getOrdersByUser } from "../../../api/axios";
import { useOptionContext } from "../../../contexts/OptionContextProvider";
import "../css/orderHistory.css";
function OrderHistory() {
	const navigate = useNavigate();
	const { user, userOrders, setUserOrders, setUser } = useOptionContext();
	useEffect(() => {
		setUser(JSON.parse(localStorage.getItem("user")) || {});
		getOrdersByUser(setUserOrders, user.email);
	}, []);
	return (
		<div className="order-history">
			<span
				className="back-button"
				onClick={() => {
					navigate("/");
				}}>
				<FiChevronLeft />
			</span>
			<h1 className="headline">Order History</h1>
			<div className="order-container">
				{userOrders.map((order) => {
					return (
						<div key={order.id} className="user-order">
							<h2 className="order-title">Order {order.id}</h2>
							<div className="content-wrapper">
								<span className="order-quantity">{order.quantity}x</span>
								<span className="order-name">{order.name}</span>
								<span className="order-price"> {order.price} $</span>
								<span className="order-date">
									{order.order_date === null ? order.order_date : order.order_date.slice(0, 10)}
								</span>
								<span className="order-status"> {order.status}</span>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default OrderHistory;
