/* eslint-disable react/no-unknown-property */
import React, { useRef, useState } from "react";
import { Html, useGLTF } from "@react-three/drei";
import { useOptionContext } from "../../contexts/OptionContextProvider";

export default function Badroom(props) {
	const { nodes, materials } = useGLTF("./models/bedroom.glb");
	const wardrobe = useRef();
	const table = useRef();
	const beanbag = useRef();
	const chair = useRef();
	const bed = useRef();
	const decorations = useRef();

	const { setCategoriesOpen } = useOptionContext();
	const [hovered, setHovered] = useState(null);

	return (
		<group {...props} dispose={null}>
			<mesh
				geometry={nodes.schrank.geometry}
				material={materials.Table}
				position={[2.4, 0.9, -3.22]}
				scale={[1, 1, 0.77]}
				name="Wardrobe"
				ref={wardrobe}
				onClick={() => handleClick(wardrobe)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(wardrobe)}
				castShadow>
				{hovered === "Wardrobe" && (
					<Html center className="tool-tip" position={[0, 5.5, 0]}>
						Wardrobe
					</Html>
				)}
			</mesh>
			<group>
				{hovered === "Table" && (
					<Html center className="tool-tip" position={[-4, 3, 0]}>
						Table
					</Html>
				)}
				<mesh
					geometry={nodes.Table.geometry}
					material={materials.Table}
					position={[2.14, 2.12, -2.06]}
					scale={[1, 1, 1.65]}
					name="Table"
					ref={table}
					onClick={() => handleClick(table)}
					onPointerOut={() => handleMouseOut()}
					onPointerEnter={() => handleMouseOver(table)}
					castShadow></mesh>
				<mesh
					geometry={nodes.Plane.geometry}
					material={materials["Material.001"]}
					position={[-3.34, 2.25, 1.53]}
					rotation={[0, 0.14, 0]}
					scale={[0.18, 0.18, 0.55]}
					castShadow
				/>
			</group>

			<mesh
				geometry={nodes.Sitting_bag.geometry}
				material={materials["Material.002"]}
				position={[0.67, 2.21, 0.99]}
				name="Beanbag"
				ref={beanbag}
				onClick={() => handleClick(beanbag)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(beanbag)}
				castShadow>
				{hovered === "Beanbag" && (
					<Html center className="tool-tip" position={[1.5, 0.6, 0]}>
						Beanbag
					</Html>
				)}
			</mesh>
			<mesh
				geometry={nodes.Chair.geometry}
				material={materials.Table}
				position={[-1.57, 1.86, 1.37]}
				rotation={[0, 0.66, 0]}
				name="Chair"
				ref={chair}
				onClick={() => handleClick(chair)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(chair)}
				castShadow>
				{hovered === "Chair" && (
					<Html center className="tool-tip" position={[0.5, 2, 0]}>
						Chair
					</Html>
				)}
			</mesh>

			<group
				name="Decorations"
				ref={decorations}
				onClick={() => handleClick(decorations)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(decorations)}
				castShadow>
				{hovered === "Decorations" && (
					<Html center className="tool-tip" position={[1, 7.2, 0]}>
						Decorations
					</Html>
				)}
				<mesh
					geometry={nodes.Images.geometry}
					material={materials.Floor}
					position={[2.56, 2.21, -1.9]}
					scale={[1, 1, 0.33]}
					castShadow
				/>

				<mesh
					geometry={nodes.Image1.geometry}
					material={materials.Image}
					position={[2.56, 2.21, -1.9]}
					scale={[1, 1, 0.33]}
					castShadow
				/>
				<mesh
					geometry={nodes.Imange2.geometry}
					material={materials["Material.003"]}
					position={[2.56, 2.21, -1.9]}
					scale={[1, 1, 0.33]}
					castShadow
				/>
				<mesh
					geometry={nodes.Image3.geometry}
					material={materials["Material.004"]}
					position={[2.56, 2.21, -1.9]}
					scale={[1, 1, 0.33]}
					castShadow
				/>
			</group>

			<group
				name="Bed"
				ref={bed}
				onClick={() => handleClick(bed)}
				onPointerOut={() => handleMouseOut()}
				onPointerEnter={() => handleMouseOver(bed)}
				castShadow>
				{hovered === "Bed" && (
					<Html center className="tool-tip" position={[0.5, 4.5, 0]}>
						Bed
					</Html>
				)}
				<mesh
					geometry={nodes.Feets.geometry}
					material={materials.Material}
					position={[-1.53, 1.33, -2.63]}
					scale={[2.49, 1, 1.34]}
					castShadow
				/>
				<mesh
					geometry={nodes.head.geometry}
					material={materials.Material}
					position={[-1.41, 0.85, -0.45]}
					scale={[0.23, 0.3, 0.3]}
					castShadow
				/>
				<mesh
					geometry={nodes.sheet.geometry}
					material={materials.Teppich}
					position={[-0.85, 3.29, -1.47]}
					scale={[0.76, 1, 1]}
					castShadow
				/>
			</group>

			<mesh
				geometry={nodes.Mat.geometry}
				material={materials.White}
				position={[-1.53, 1.33, -2.63]}
				scale={[2.49, 1, 1.34]}
				castShadow
			/>
			<mesh geometry={nodes.Ground.geometry} material={materials.Walls} />
			<mesh
				geometry={nodes.Floor.geometry}
				material={materials.Floor}
				position={[0.15, 0.89, 0]}
				scale={[0.97, 1, 1]}
			/>
			<mesh geometry={nodes.Walls.geometry} material={materials.Walls} position={[-4.38, 0, 0]} />
			<mesh
				geometry={nodes.Object.geometry}
				material={materials.Teppich}
				position={[1.45, 0.89, 1.64]}
				rotation={[-Math.PI, 0, -Math.PI]}
				scale={[-1.87, -0.03, -1.87]}
			/>
		</group>
	);
	//handle hoverEffect
	function handleMouseOver(ref) {
		setHovered(ref.current.name);
		document.querySelector("canvas").style.cursor = "pointer";
	}
	function handleMouseOut() {
		document.querySelector("canvas").style.cursor = "default";
		setHovered(null);
	}
	//handle click

	function handleClick(object) {
		setCategoriesOpen(object.current.name);
	}
}

useGLTF.preload("/models/bedroom.glb");
