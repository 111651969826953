import React, { useEffect, useState } from "react";
import "../css/administration.css";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts";
import { DataGrip, SalesPanel, ShopItems, StatusPanel, UserCount } from "../components/Panels";
import { AiOutlineShop } from "react-icons/ai";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { enableRipple } from "@syncfusion/ej2-base";
import { useNavigate } from "react-router-dom";
import { getAllOrders } from "../../../api/axios";
import { useOptionContext } from "../../../contexts/OptionContextProvider";

import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-react-layouts/styles/material.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-calendars/styles/material.css";
import "@syncfusion/ej2-dropdowns/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import "@syncfusion/ej2-notifications/styles/material.css";
import "@syncfusion/ej2-react-grids/styles/material.css";

const cellSpacing = [30, 30];

function Administration() {
	const { setOrders } = useOptionContext();
	useEffect(() => {
		getAllOrders(setOrders);
	}, []);
	enableRipple(true);
	const navigate = useNavigate();
	const [resize, setResize] = useState(false);
	const [moving, setMoving] = useState(false);

	return (
		<div className="admin-site">
			<div className="header">
				<div className="header-title">Interior Design</div>
				<div className="header-subtitle">Administation</div>
				<div>
					<div className="nav">
						<SwitchComponent
							className="edit-switch"
							change={() => {
								setResize(!resize);
								setMoving(!moving);
							}}
						/>
						<AiOutlineShop className="shop-icon" onClick={() => navigate("/")} />
					</div>
				</div>
			</div>

			<div>
				<div className="control-section">
					<DashboardLayoutComponent
						id="defaultLayout"
						cellSpacing={cellSpacing}
						cellAspectRatio={window.innerWidth / window.innerHeight}
						allowResizing={resize}
						allowDragging={moving}
						columns={6}>
						<div id="one" className="e-panel" data-row="0" data-col="4" data-sizex="2" data-sizey="1">
							<SalesPanel />
						</div>

						<div id="tree" className="e-panel" data-row="1" data-col="4" data-sizex="2" data-sizey="1">
							<UserCount />
						</div>
						<div id="two" className="e-panel" data-row="3" data-col="4" data-sizex="2" data-sizey="3">
							<StatusPanel />
						</div>
						<div
							id="four"
							style={{ overflow: "hidden" }}
							className="e-panel"
							data-row="0"
							data-col="0"
							data-sizex="4"
							data-sizey="2">
							<DataGrip />
						</div>
						<div id="five" className="e-panel" data-row="3" data-col="0" data-sizex="4" data-sizey="3">
							<ShopItems />
						</div>
					</DashboardLayoutComponent>
				</div>
			</div>
		</div>
	);
}

export default Administration;
