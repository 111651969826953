import React from "react";
import "../css/components.css";
import Menu from "../components/Menu";
import ShoppingCart from "../components/ShoppingCart";
import { useOptionContext } from "../../../contexts/OptionContextProvider";
import ShoppingList from "../components/ShoppingList";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import * as THREE from "three";
import { Canvas } from "@react-three/fiber";
import R3F from "../../rooms/R3F";

function Shopping() {
	const {
		menuActive,
		cartActive,
		setMenuActive,
		setCartActive,
		categoriesOpen,
		setCategoriesOpen,
		lookAt,
		setLookAt,
	} = useOptionContext();
	const siteTitle = document.querySelector(".room-title");
	return (
		<>
			<Menu />
			<ShoppingCart />
			<ShoppingList />
			<div className="site-title">
				<h1>Interior Design</h1>
				<h3 className="room-title">{lookAt?.name}</h3>
			</div>

			<div
				className="layer"
				style={{
					display: cartActive || menuActive || categoriesOpen !== null ? "block" : "none",
				}}
				onClick={() => {
					setCartActive(false);
					setMenuActive(false);
					setCategoriesOpen(null);
				}}></div>
			<FiChevronRight
				className="next-room"
				onClick={() => {
					siteTitle.classList.add("animation");
					setTimeout(() => {
						siteTitle.classList.remove("animation");
					}, 1000);
					switch (lookAt.name) {
						case "Kitchen":
							setLookAt({
								name: "Livingroom",
								position: new THREE.Vector3(0, 2, 15),
								menuPosition: new THREE.Vector3(-5, 2, 15),
								listPosition: new THREE.Vector3(5, 2, 15),
							});
							break;
						case "Livingroom":
							setLookAt({
								name: "Bethroom",
								position: new THREE.Vector3(-15, 2, 0),
								menuPosition: new THREE.Vector3(-15, 2, -5),
								listPosition: new THREE.Vector3(-15, 2, 5),
							});
							break;
						case "Bethroom":
							setLookAt({
								name: "Badroom",
								position: new THREE.Vector3(0, 2, -15),
								menuPosition: new THREE.Vector3(5, 2, -15),
								listPosition: new THREE.Vector3(-5, 2, -15),
							});
							break;
						case "Badroom":
							setLookAt({
								name: "Kitchen",
								position: new THREE.Vector3(15, 2, 0),
								menuPosition: new THREE.Vector3(15, 2, 5),
								listPosition: new THREE.Vector3(15, 2, -5),
							});
							break;
					}
				}}
			/>
			<FiChevronLeft
				className="previous-room"
				onClick={() => {
					siteTitle.classList.add("animation");
					setTimeout(() => {
						siteTitle.classList.remove("animation");
					}, 1000);
					switch (lookAt.name) {
						case "Badroom":
							setLookAt({
								name: "Bethroom",
								position: new THREE.Vector3(-15, 2, 0),
								menuPosition: new THREE.Vector3(-15, 2, -5),
								listPosition: new THREE.Vector3(-15, 2, 5),
							});
							break;
						case "Livingroom":
							setLookAt({
								name: "Kitchen",
								position: new THREE.Vector3(15, 2, 0),
								menuPosition: new THREE.Vector3(15, 2, 5),
								listPosition: new THREE.Vector3(15, 2, -5),
							});
							break;
						case "Bethroom":
							setLookAt({
								name: "Livingroom",
								position: new THREE.Vector3(0, 2, 15),
								menuPosition: new THREE.Vector3(-5, 2, 15),
								listPosition: new THREE.Vector3(5, 2, 15),
							});
							break;
						case "Kitchen":
							setLookAt({
								name: "Badroom",
								position: new THREE.Vector3(0, 2, -15),
								menuPosition: new THREE.Vector3(5, 2, -15),
								listPosition: new THREE.Vector3(-5, 2, -15),
							});
							break;
					}
				}}
			/>

			<Canvas
				shadows
				camera={{
					fov: 45,
					near: 0.1,
					far: 200,
					position: [0, 10, 0],
				}}>
				<R3F />
			</Canvas>
		</>
	);
}
export default Shopping;
