import React from "react";
import { FiX } from "react-icons/fi";
import { useOptionContext } from "../../../contexts/OptionContextProvider";
import { addToCart, removeFromCart, increaseQuantity, decreaseQuantity } from "../../../helper/CartFunktions.jsx";
function ShoppingList() {
	const { categoriesOpen, setCategoriesOpen, cartItems, setCartItems, items } = useOptionContext();

	return (
		<div
			className={`html-display `}
			style={{
				marginLeft: categoriesOpen !== null ? "0px" : "-500px",
			}}>
			<div>
				<div className="category-header">
					<div className="category-title">{categoriesOpen}</div>
					<FiX
						className="close-button"
						onClick={() => {
							setCategoriesOpen(null);
						}}
					/>
				</div>

				<div className="item-container">
					{items.map((item) => {
						if (item.category === categoriesOpen) {
							return (
								<div className="item" key={item.id}>
									<div
										className="item-image"
										style={{ backgroundImage: `url(${item.image_src})`, height: "200px" }}></div>
									<div className="item-head">
										<div className="item-name">{item.name}</div>
										<div className="item-price">{item.price}$</div>
									</div>

									<div className="item-description">{item.description}</div>

									<div className="button-container">
										{cartItems.find((cartItem) => cartItem.id === item.id) == null ? (
											<button
												className="add-to-cart-button"
												onClick={() => {
													addToCart(item, setCartItems, cartItems);
												}}>
												Add to cart
											</button>
										) : (
											<div>
												<div className="button-wrapper">
													<button
														className="decrease-quantity-button"
														onClick={() => {
															decreaseQuantity(item, setCartItems, cartItems);
														}}>
														-
													</button>

													<div>
														<div className="text">
															<div className="quantity">
																{
																	cartItems.find(
																		(cartItem) => cartItem.id === item.id
																	).quantity
																}
															</div>
															<div className="quantity-text"> in cart</div>
														</div>
													</div>
													<button
														className="increase-quantity-button"
														onClick={() => {
															increaseQuantity(item, setCartItems, cartItems);
														}}>
														+
													</button>
												</div>
												<div className="remove-wrapper">
													<button
														className="remove-from-cart-button"
														onClick={() => {
															removeFromCart(item, setCartItems, cartItems);
														}}>
														Remove
													</button>
												</div>
											</div>
										)}
									</div>
								</div>
							);
						}
					})}
				</div>
			</div>
		</div>
	);
}

export default ShoppingList;
