/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState } from "react";
import * as THREE from "three";
const OptionContext = createContext();

export const OptionContextProvider = ({ children }) => {
	const [user, setUser] = useState({
		loggedIn: false,
		first_name: null,
		last_name: null,
		email: null,
		address: null,
		city: null,
		zip: null,
		country: null,
		same_as_shipping: true,
		billing_first_name: null,
		billing_last_name: null,
		billing_email: null,
		billing_address: null,
		billing_city: null,
		billing_zip: null,
	});
	const [menuActive, setMenuActive] = useState(false);
	const [cartActive, setCartActive] = useState(false);
	const [cartItems, setCartItems] = useState([]);
	const [categoriesOpen, setCategoriesOpen] = useState(null);
	const [lookAt, setLookAt] = useState({
		name: "Kitchen",
		position: new THREE.Vector3(15, 2, 0),
		menuPosition: new THREE.Vector3(15, 2, 5),
		listPosition: new THREE.Vector3(15, 2, -5),
	});

	const [items, setItems] = useState([]);
	const [orders, setOrders] = useState([]);
	const [userOrders, setUserOrders] = useState([]);

	return (
		<OptionContext.Provider
			value={{
				user,
				setUser,
				menuActive,
				setMenuActive,
				cartActive,
				setCartActive,
				cartItems,
				setCartItems,
				categoriesOpen,
				setCategoriesOpen,
				items,
				setItems,
				lookAt,
				setLookAt,
				orders,
				setOrders,
				userOrders,
				setUserOrders,
			}}>
			{children}
		</OptionContext.Provider>
	);
};

export const useOptionContext = () => useContext(OptionContext);
