/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { FiShoppingCart, FiTrash2, FiX } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useOptionContext } from "../../../contexts/OptionContextProvider";
import { removeFromCart, increaseQuantity, decreaseQuantity } from "../../../helper/CartFunktions.jsx";
function ShoppingCart() {
	const navigate = useNavigate();
	const { cartActive, menuActive, setCartActive, cartItems, setCartItems } = useOptionContext();

	let totalAmount = 0;
	let totalQuantity = 0;
	cartItems.forEach((item) => {
		totalAmount += item.price * item.quantity;
		totalQuantity += item.quantity;
	});
	return (
		<div className="shopping-chart">
			<div className="menu-title">Cart</div>
			{!menuActive ? (
				!cartActive ? (
					<>
						<FiShoppingCart
							className="open-button"
							onClick={() => {
								setCartActive(true);
							}}
						/>
						{cartItems.length > 0 && <div className="cart-quantity">{totalQuantity}</div>}
					</>
				) : (
					<FiX
						className="close-button"
						onClick={() => {
							setCartActive(false);
						}}
					/>
				)
			) : null}
			<div className="open-menu" style={{ marginRight: cartActive ? "0px" : "-500px" }}>
				<div className="item-container">
					{cartItems.map((item) => {
						return (
							<div className="item item-in-cart" key={item.id}>
								<div className="item-left">
									<div className="item-name">{item.name}</div>
									<div className="item-price">{item.price}$</div>
								</div>

								<div className="button-wrapper">
									<button
										className="decrease-quantity-button"
										onClick={() => {
											decreaseQuantity(item, setCartItems, cartItems);
										}}>
										-
									</button>

									<div>
										<div className="text">
											<div className="quantity">
												{cartItems.find((cartItem) => cartItem.id === item.id).quantity}
											</div>
											<div className="quantity-text"> in cart</div>
										</div>
									</div>
									<button
										className="increase-quantity-button"
										onClick={() => {
											increaseQuantity(item, setCartItems, cartItems);
										}}>
										+
									</button>
								</div>
								<div className="remove-wrapper">
									<div
										className="remove-from-cart-button"
										onClick={() => {
											removeFromCart(item, setCartItems, cartItems);
										}}>
										<FiTrash2 />
									</div>
								</div>
							</div>
						);
					})}
				</div>
				<div className="cart-placeholder" style={{ display: cartItems.length === 0 ? "flex" : "none" }}>
					<p>
						You don't have <br />
						any items in <br />
						your shopping cart yet.
						<br />
						<br />
						Start shopping now!
					</p>
				</div>
				<div className="cart-bottom" style={{ display: cartItems.length > 0 ? "block" : "none" }}>
					<div className="total-amount">
						Sum ({totalQuantity} {totalQuantity === 1 ? "Item" : "Items"}): {totalAmount.toFixed(2)}$
					</div>
					<div
						className="checkout-button"
						onClick={() => {
							navigate("/checkout");
						}}>
						Checkout
					</div>
				</div>
			</div>
		</div>
	);
}

export default ShoppingCart;
