/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import "../css/payment.css";
import { useOptionContext } from "../../../contexts/OptionContextProvider";
import AdressForm from "../components/AdressForm";
import BillingAdressForm from "../components/BillingAdressForm";
import { createPaymentIntent, getStripeKey } from "../../../api/axios";
import { FiChevronLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

function Payment() {
	const navigate = useNavigate();
	const [stripePromise, setStripePromise] = useState(null);
	const [clientSecret, setClientSecret] = useState("");
	const { cartItems } = useOptionContext();
	let totalAmount = 0;
	cartItems.forEach((item) => {
		totalAmount += item.price * item.quantity;
	});
	totalAmount *= 100;
	useEffect(() => {
		async function loadStripePromise() {
			setStripePromise(loadStripe(await getStripeKey()));
		}
		loadStripePromise();
	}, []);

	useEffect(() => {
		console.log("totalAmount", totalAmount);
		async function createAPaymentIntent() {
			await createPaymentIntent(setClientSecret, totalAmount);
		}
		createAPaymentIntent();
	}, []);

	return (
		<div className="payment">
			<h1 className="payment-title">Order and Pay</h1>
			<span
				className="back-button"
				onClick={() => {
					navigate("/");
				}}>
				<FiChevronLeft />
			</span>
			<div className="payment-container">
				<AdressForm />
				<BillingAdressForm />
				<div className="cart-summary">
					<h3 className="form-title">Cart Summary</h3>
					<div className="cart-summary-container">
						{cartItems.map((item) => (
							<div className="cart-summary-item" key={item.id}>
								<div className="cart-summary-item-info">
									<p className="cart-summary-item-price">
										{item.quantity} x {item.name} ({item.price} €)
									</p>
								</div>
							</div>
						))}
						<div className="cart-summary-total">Total: {totalAmount / 100} €</div>
					</div>
				</div>

				{clientSecret && stripePromise && (
					<Elements stripe={stripePromise} options={{ clientSecret }}>
						<CheckoutForm />
					</Elements>
				)}
			</div>
		</div>
	);
}

export default Payment;
