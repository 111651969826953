/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import * as React from "react";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { useOptionContext } from "../../../contexts/OptionContextProvider";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";

let formObject;
function BillingAdressForm() {
	const { user, setUser } = useOptionContext();

	const update = (field) => (event) => {
		//update action is dispatched and triggered state changes
		setUser((state) => ({ ...state, [field]: event.value }));
	};
	useEffect(() => {
		const options = {
			// validation rules
			rules: {
				billing_first_name: {
					required: [true, "* Please enter your first name"],
				},
				billing_last_name: {
					required: [true, "* Please enter your last name"],
				},
				billing_email: {
					required: [true, "* Please enter your email"],
				},
				billing_address: {
					required: [true, "* Please enter your address"],
				},
				billing_city: {
					required: [true, "* Please enter your city"],
				},
				billing_zip: {
					required: [true, "* Please enter your zip"],
				},
				billing_country: {
					required: [true, "* Please enter your country"],
				},
			},
		};
		// Initialize the form validator
		formObject = new FormValidator("#form2", options);
	}, []);
	return (
		<div>
			<h3 className="form-title">Billing Address</h3>
			<CheckBoxComponent
				label="Same as shipping address"
				checked={user.same_as_shipping}
				onChange={() => {
					setUser((state) => ({ ...state, same_as_shipping: !state.same_as_shipping }));
				}}
			/>
			{!user.same_as_shipping && (
				<div className="control_wrapper" id="control_wrapper">
					<div className="control_wrapper textbox-form">
						<div id="form2" method="post">
							<div className="form-group">
								<TextBoxComponent
									name="billing_first_name"
									value={user.billing_first_name}
									change={update("billing_first_name")}
									placeholder="First Name"
									floatLabelType="Auto"
									data-msg-containerid="errorForFirstName"
								/>
								<div id="errorForFirstName" />
							</div>
							<div className="form-group">
								<TextBoxComponent
									name="billing_last_name"
									value={user.billing_last_name}
									change={update("billing_last_name")}
									placeholder="Last Name"
									floatLabelType="Auto"
									data-msg-containerid="errorForLastName"
								/>
								<div id="errorForLastName" />
							</div>
							<div className="form-group">
								<TextBoxComponent
									type="email"
									name="billing_email"
									value={user.billing_email}
									change={update("billing_email")}
									placeholder="Email"
									floatLabelType="Auto"
									data-msg-containerid="errorForEmail"
								/>
								<div id="errorForEmail" />
							</div>
							<div className="form-group">
								<TextBoxComponent
									name="billing_address"
									value={user.billing_address}
									change={update("billing_address")}
									placeholder="Address"
									floatLabelType="Auto"
									data-msg-containerid="errorForAddress"
								/>
								<div id="errorForAddress" />
							</div>
							<div className="form-group">
								<TextBoxComponent
									name="billing_city"
									value={user.billing_city}
									change={update("billing_city")}
									placeholder="City"
									floatLabelType="Auto"
									data-msg-containerid="errorForCity"
								/>
								<div id="errorForCity" />
							</div>
							<div className="form-group">
								<TextBoxComponent
									name="billing_zip"
									value={user.billing_zip}
									change={update("billing_zip")}
									placeholder="Zip"
									floatLabelType="Auto"
									data-msg-containerid="errorForZip"
								/>
								<div id="errorForZip" />
							</div>
							<div className="form-group">
								<TextBoxComponent
									name="billing_country"
									value={user.billing_country}
									change={update("billing_country")}
									placeholder="Country"
									floatLabelType="Auto"
									data-msg-containerid="errorForCountry"
								/>
								<div id="errorForCountry" />
							</div>
						</div>
					</div>
					<br />
					<br />
				</div>
			)}
		</div>
	);
}

export default BillingAdressForm;
