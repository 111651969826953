import { useState } from "react";
import { useOptionContext } from "../../contexts/OptionContextProvider";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

export default function Room() {
	const { categoriesOpen, menuActive, cartActive, lookAt } = useOptionContext();
	const [smoothedCameraTarget] = useState(new THREE.Vector3());
	useFrame((state, delta) => {
		if (menuActive || cartActive) {
			const cameraTarget = lookAt.menuPosition;
			smoothedCameraTarget.lerp(cameraTarget, 5 * delta);
			state.camera.lookAt(smoothedCameraTarget);
		} else if (categoriesOpen !== null) {
			const cameraTarget = lookAt.listPosition;
			smoothedCameraTarget.lerp(cameraTarget, 5 * delta);
			state.camera.lookAt(smoothedCameraTarget);
		} else {
			smoothedCameraTarget.lerp(lookAt.position, 3.5 * delta);

			state.camera.lookAt(smoothedCameraTarget);
		}
	});
	return;
}
