/* eslint-disable react/prop-types */
import { useKeycloak } from "@react-keycloak/web";

const PrivateRoute = ({ children }) => {
	const { keycloak } = useKeycloak();

	const isAdmin = keycloak.hasRealmRole("adminRole");

	return isAdmin ? children : null;
};

export default PrivateRoute;
