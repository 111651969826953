/* eslint-disable react/react-in-jsx-scope */
import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useOptionContext } from "../../../contexts/OptionContextProvider";
import { createOrders } from "../../../api/axios";
import { toast } from "react-toastify";

export default function CheckoutForm() {
	const { user, cartItems } = useOptionContext();
	const stripe = useStripe();
	const elements = useElements();
	const [message, setMessage] = useState(null);
	const [isProcessing, setIsProcessing] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			return;
		}

		setIsProcessing(true);
		localStorage.setItem("user", JSON.stringify(user));
		createOrders(user, cartItems);
		localStorage.removeItem("cartItems");
		toast.success("Payment successful!");
		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: `${window.location.origin}`,
			},
		});

		if (error.type === "card_error" || error.type === "validation_error") {
			setMessage(error.message);
			toast.error(error.message);
		} else {
			setMessage("An unexpected error occured.");
			toast.error("An unexpected error occured.");
		}

		setIsProcessing(false);
	};

	return (
		<form id="payment-form" onSubmit={handleSubmit}>
			<PaymentElement id="payment-element" />
			<button
				disabled={
					isProcessing ||
					!stripe ||
					!elements ||
					user.first_name === null ||
					user.last_name === null ||
					user.email === null ||
					user.address === null ||
					user.zip === null ||
					user.country === null ||
					user.city === null ||
					(user.same_as_shipping === false &&
						(user.billing_first_name === null ||
							user.billing_last_name === null ||
							user.billing_email === null ||
							user.billing_address === null ||
							user.billing_zip === null ||
							user.billing_country === null ||
							user.billing_city === null))
				}
				id="submit">
				<span id="button-text">{isProcessing ? "Processing ... " : "Pay now"}</span>
			</button>
			{/* Show any error or success messages */}
			{message && <div id="payment-message">{message}</div>}
		</form>
	);
}
