/* eslint-disable react/react-in-jsx-scope */
import "./style.css";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { OptionContextProvider } from "./contexts/OptionContextProvider";
import { registerLicense } from "@syncfusion/ej2-base";
import keycloak from "./api/Keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Registering Syncfusion license key
registerLicense(
	"Mgo+DSMBaFt/QHRqVVhkVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jS3xXdkNiWHxbd3JQQw==;Mgo+DSMBPh8sVXJ0S0J+XE9AflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdEdnWXlfcXdQQWldVQ==;ORg4AjUWIQA/Gnt2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdhXX9YcXVWQ2hcVUA=;MTE5NzY4MUAzMjMwMmUzNDJlMzBhQkNVdmNvRkV2RXVjZjIzemFtQ1dKUnE5QTB1TTZ2TmErTCtCY2NqYmdjPQ==;MTE5NzY4MkAzMjMwMmUzNDJlMzBvWFMyUW9Tc01ZamhJV2tLVVE4ejYrVS9vd1FaYmdjd1RsMnEzY1ZTVGJZPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFtKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVhW3tednVURWVUWUJ1;MTE5NzY4NEAzMjMwMmUzNDJlMzBpc0NDWE5TQ1dqMVR0bUpSdDhYNlFLZk9lUzhNblhsajNsWGMwTlhyMXVRPQ==;MTE5NzY4NUAzMjMwMmUzNDJlMzBTMFJwa1dDR0dIdlhzbVQ4VmxveUxYdUwzRDdtOTgwcW1sT3NyM3VxNFgwPQ==;Mgo+DSMBMAY9C3t2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdhXX9YcXVWQGBdWEA=;MTE5NzY4N0AzMjMwMmUzNDJlMzBWZzZLQVMrcUhKV204dGJMb0NQRndoNWJRZkxCQWh2WDNpbzFzb1liczhrPQ==;MTE5NzY4OEAzMjMwMmUzNDJlMzBGNk5kLzAybDlySjBIamJ5cjR0NjFWcnV6UUxqbERScnkrYmNCWTBwZWxJPQ==;MTE5NzY4OUAzMjMwMmUzNDJlMzBpc0NDWE5TQ1dqMVR0bUpSdDhYNlFLZk9lUzhNblhsajNsWGMwTlhyMXVRPQ=="
);

const root = ReactDOM.createRoot(document.querySelector("#root"));

root.render(
	<OptionContextProvider>
		<ReactKeycloakProvider authClient={keycloak}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
			<ToastContainer />
		</ReactKeycloakProvider>
	</OptionContextProvider>
);

