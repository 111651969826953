import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import { FiX, FiMenu } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useOptionContext } from "../../../contexts/OptionContextProvider";
function Menu() {
	const { keycloak } = useKeycloak();
	const { menuActive, cartActive, setMenuActive } = useOptionContext();
	const navigate = useNavigate();

	return (
		<div className="menu">
			<div className="menu-title">Menu</div>
			{!cartActive ? (
				!menuActive ? (
					<FiMenu
						className="open-button"
						onClick={() => {
							setMenuActive(true);
						}}
					/>
				) : (
					<FiX
						className="close-button"
						onClick={() => {
							setMenuActive(false);
						}}
					/>
				)
			) : null}

			<div className="open-menu" style={{ marginRight: menuActive ? "0px" : "-500px" }}>
				<div className="menu-body">
					<div className="hover:text-gray-200">
						{!keycloak.authenticated && (
							<div className="login-button" onClick={() => keycloak.login()}>
								Login
							</div>
						)}

						{!!keycloak.authenticated && (
							<div className="text-blue-800" onClick={() => keycloak.logout()}>
								Logout ({keycloak.tokenParsed.preferred_username})
							</div>
						)}
					</div>
					{!!keycloak.authenticated && (
						<div
							className="register-button"
							onClick={() => {
								navigate("/history");
							}}>
							Order History
						</div>
					)}
					{keycloak.hasRealmRole("adminRole") && (
						<div className="navigate-button" onClick={() => navigate("/administration")}>
							Monitor
						</div>
					)}
				</div>

				<div className="menu-footer">© 2023 All rights reserved by OneDash.</div>
			</div>
		</div>
	);
}

export default Menu;
