/* eslint-disable no-unused-vars */
import { useEffect, useRef } from "react";
import * as React from "react";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { useOptionContext } from "../../../contexts/OptionContextProvider";
let formObject;
function AdressForm() {
	const { user, setUser } = useOptionContext();
	const userNameRef = useRef(null);

	const update = (field) => (event) => {
		//update action is dispatched and triggered state changes
		setUser((state) => ({ ...state, [field]: event.value }));
	};
	useEffect(() => {
		userNameRef.current.focusIn();
		const options = {
			// validation rules
			rules: {
				first_name: {
					required: [true, "* Please enter your first name"],
				},
				last_name: {
					required: [true, "* Please enter your last name"],
				},
				email: {
					required: [true, "* Please enter your email"],
				},
				address: {
					required: [true, "* Please enter your address"],
				},
				city: {
					required: [true, "* Please enter your city"],
				},
				zip: {
					required: [true, "* Please enter your zip"],
				},
				country: {
					required: [true, "* Please enter your country"],
				},
			},
		};
		// Initialize the form validator
		formObject = new FormValidator("#form1", options);
	}, []);
	return (
		<div>
			<div className="control_wrapper" id="control_wrapper">
				<h3 className="form-title">Address</h3>
				<div className="control_wrapper textbox-form">
					<div id="form1" method="post">
						<div className="form-group">
							<TextBoxComponent
								ref={userNameRef}
								name="first_name"
								value={user.first_name}
								change={update("first_name")}
								placeholder="First Name"
								floatLabelType="Auto"
								data-msg-containerid="errorForFirstName"
							/>
							<div id="errorForFirstName" />
						</div>
						<div className="form-group">
							<TextBoxComponent
								name="last_name"
								value={user.last_name}
								change={update("last_name")}
								placeholder="Last Name"
								floatLabelType="Auto"
								data-msg-containerid="errorForLastName"
							/>
							<div id="errorForLastName" />
						</div>
						<div className="form-group">
							<TextBoxComponent
								type="email"
								name="email"
								value={user.email}
								change={update("email")}
								placeholder="Email"
								floatLabelType="Auto"
								data-msg-containerid="errorForEmail"
							/>
							<div id="errorForEmail" />
						</div>
						<div className="form-group">
							<TextBoxComponent
								name="address"
								value={user.address}
								change={update("address")}
								placeholder="Address"
								floatLabelType="Auto"
								data-msg-containerid="errorForAddress"
							/>
							<div id="errorForAddress" />
						</div>
						<div className="form-group">
							<TextBoxComponent
								name="city"
								value={user.city}
								change={update("city")}
								placeholder="City"
								floatLabelType="Auto"
								data-msg-containerid="errorForCity"
							/>
							<div id="errorForCity" />
						</div>
						<div className="form-group">
							<TextBoxComponent
								name="zip"
								value={user.zip}
								change={update("zip")}
								placeholder="Zip"
								floatLabelType="Auto"
								data-msg-containerid="errorForZip"
							/>
							<div id="errorForZip" />
						</div>
						<div className="form-group">
							<TextBoxComponent
								name="country"
								value={user.country}
								change={update("country")}
								placeholder="Country"
								floatLabelType="Auto"
								data-msg-containerid="errorForCountry"
							/>
							<div id="errorForCountry" />
						</div>
					</div>
				</div>
				<br />
				<br />
			</div>
		</div>
	);
}

export default AdressForm;
