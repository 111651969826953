/* eslint-disable react/no-unknown-property */
/* eslint-disable react/react-in-jsx-scope */
import { Suspense } from "react";
import Room from "./Room";
import Kitchen from "./Kitchen";
import Badroom from "./Bedroom";
import Livingroom from "./Livingroom";
import Bathroom from "./Bathroom";

export default function R3F() {
	return (
		<>
			{/* Light */}
			<directionalLight castShadow position={[0, 1, 0]} intensity={1.2} color="#fcffbc" />

			<ambientLight intensity={0.5} />

			<Suspense fallback={null}>
				{/* Room */}

				<Kitchen rotation-y={Math.PI * 0.3} scale={1.4} position={[15, 0, 0]} />
				<Badroom rotation-y={Math.PI * 1.8} position={[0, 0, -15]} />
				<Livingroom rotation-y={Math.PI * 0.8} position={[0, 0, 15]} />
				<Bathroom rotation-y={Math.PI * 0.3} position={[-15, 0, 0]} />
				<Room />
			</Suspense>
		</>
	);
}

