import React from "react";
import { useOptionContext } from "../../../contexts/OptionContextProvider";
import {
	AccumulationChartComponent,
	AccumulationLegend,
	AccumulationSeriesCollectionDirective,
	AccumulationSeriesDirective,
	AccumulationTooltip,
} from "@syncfusion/ej2-react-charts";
import {
	ColumnDirective,
	ColumnsDirective,
	Edit,
	Filter,
	GridComponent,
	Group,
	Inject,
	Page,
	Sort,
	Toolbar,
} from "@syncfusion/ej2-react-grids";
import { addItems, changeItems, deleteItems } from "../../../api/axios";
import { useKeycloak } from "@react-keycloak/web";

export function SalesPanel() {
	return (
		<div style={{ padding: "20px" }}>
			<h1>Daily Sales</h1>
			<h3>349</h3>
		</div>
	);
}

export function StatusPanel() {
	const { orders } = useOptionContext();
	let statusData = [];
	let pending = 0;
	let completed = 0;
	let shipped = 0;
	orders.forEach((order) => {
		if (order.status === "pending") {
			pending++;
		} else if (order.status === "completed") {
			completed++;
		} else if (order.status === "shipped") {
			shipped++;
		}
	});
	statusData.push({ x: "Pending", y: pending });
	statusData.push({ x: "Completed", y: completed });
	statusData.push({ x: "Shipped", y: shipped });

	return (
		<AccumulationChartComponent
			id="charts"
			tooltip={{ enable: true }}
			title="Status of Orders"
			legendSettings={{ visible: true }}>
			<Inject services={[AccumulationTooltip, AccumulationLegend]} />
			<AccumulationSeriesCollectionDirective>
				<AccumulationSeriesDirective
					palettes={["#41c6db", "#2cce29", "#1aba82"]}
					dataSource={statusData}
					xName="x"
					yName="y"
					innerRadius="40%"></AccumulationSeriesDirective>
			</AccumulationSeriesCollectionDirective>
		</AccumulationChartComponent>
	);
}

export function UserCount() {
	return (
		<div style={{ padding: "20px" }}>
			<h1>Users </h1>
			<h3>1210</h3>
		</div>
	);
}

export function DataGrip() {
	const { orders } = useOptionContext();
	const pageSettings = { pageSize: 5 };
	return (
		<GridComponent
			style={{ height: "100%" }}
			dataSource={orders}
			allowPaging={true}
			allowPdfExport
			allowFiltering={true}
			allowSorting={true}
			pageSettings={pageSettings}>
			<ColumnsDirective>
				<ColumnDirective field="id" headerText="Order-Id" width="100" textAlign="Right" />
				<ColumnDirective field="customer" headerText="Customer" width="100" />
				<ColumnDirective field="item" headerText="Item-Id" width="100" />
				<ColumnDirective field="quantity" headerText="Quantity" width="100" />
				<ColumnDirective field="order_date" headerText="Order Date" width="100" />
				<ColumnDirective field="country" headerText="Country" width="100" />
				<ColumnDirective field="status" width="100" />
			</ColumnsDirective>
			<Inject services={[Page, Sort, Filter, Group]} />
		</GridComponent>
	);
}

export function ShopItems() {
	const { keycloak } = useKeycloak();
	const pageSettings = { pageSize: 8 };
	const { items, setItems } = useOptionContext();

	const editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: "Batch" };
	const toolbarOptions = ["Add", "Edit", "Delete", "Update", "Cancel", "Search"];

	const onBeforeBatchSave = (args) => {
		if (args.batchChanges.changedRecords.length > 0) {
			// send args.batchChanges.changedRecords to server
			changeItems(args.batchChanges.changedRecords, setItems, keycloak);
		}
		if (args.batchChanges.addedRecords.length > 0) {
			// send args.batchChanges.addedRecords to server
			addItems(args.batchChanges.addedRecords, setItems, keycloak);
		}
		if (args.batchChanges.deletedRecords.length > 0) {
			// send args.batchChanges.deletedRecords to server
			deleteItems(args.batchChanges.deletedRecords, setItems, keycloak);
		}
	};

	return (
		<GridComponent
			beforeBatchSave={onBeforeBatchSave}
			dataSource={items}
			id="Grid"
			style={{ height: "100%" }}
			toolbar={toolbarOptions}
			allowPaging={true}
			allowFiltering={true}
			pageSettings={pageSettings}
			allowSorting={true}
			editSettings={editSettings}>
			<ColumnsDirective>
				<ColumnDirective field="category" headerText="Category" width="120" />
				<ColumnDirective field="name" headerText="Name" width="120" />
				<ColumnDirective field="description" headerText="Description" width="120" />
				<ColumnDirective field="price" headerText="Price" width="120" format={"c2"} />
				<ColumnDirective field="image_src" headerText="Image Source" width="120" />
			</ColumnsDirective>
			<Inject services={[Page, Toolbar, Edit]} />
		</GridComponent>
	);
}
