import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Payment from "./components/htlm/sites/Payment";
import Administration from "./components/htlm/sites/Administration";
import Shopping from "./components/htlm/sites/Shopping";
import PrivateRoute from "./api/PrivateRoute";
import { getAllItems } from "./api/axios";
import { useOptionContext } from "./contexts/OptionContextProvider";
import { useKeycloak } from "@react-keycloak/web";
import OrderHistory from "./components/htlm/sites/OrderHistory";

function App() {
	const { keycloak } = useKeycloak();
	// Context
	const { setItems, setCartItems, user, setUser } = useOptionContext();

	useEffect(() => {
		getAllItems(setItems);
		setUser(JSON.parse(localStorage.getItem("user")) || {});
		setCartItems(JSON.parse(localStorage.getItem("cartItems")) || []);
	}, []);

	useEffect(() => {
		if (keycloak.authenticated) {
			setUser({
				...user,
				loggedIn: true,
				email: keycloak.tokenParsed.email,
				first_name: keycloak.tokenParsed.given_name,
				last_name: keycloak.tokenParsed.family_name,
			});
		}
	}, [keycloak.authenticated]);

	return (
		<Routes>
			<Route path="/" element={<Shopping />} />
			<Route
				path="/administration"
				element={
					<PrivateRoute>
						<Administration />
					</PrivateRoute>
				}
			/>
			<Route path="/checkout" element={<Payment />} />
			<Route path="/history" element={<OrderHistory />} />
		</Routes>
	);
}

export default App;
